<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M12.0006 3C17.3926 3 21.8786 6.88 22.8196 12C21.8796 17.12 17.3926 21 12.0006 21C6.60864 21 2.12264 17.12 1.18164 12C2.12164 6.88 6.60864 3 12.0006 3ZM12.0006 19C14.0401 18.9996 16.019 18.3068 17.6135 17.0352C19.208 15.7635 20.3235 13.9883 20.7776 12C20.3219 10.0133 19.2056 8.24 17.6113 6.97003C16.017 5.70005 14.0389 5.00853 12.0006 5.00853C9.96234 5.00853 7.98433 5.70005 6.39002 6.97003C4.7957 8.24 3.67941 10.0133 3.22364 12C3.67774 13.9883 4.79331 15.7635 6.38778 17.0352C7.98225 18.3068 9.96117 18.9996 12.0006 19ZM12.0006 16.5C10.8072 16.5 9.66257 16.0259 8.81866 15.182C7.97475 14.3381 7.50064 13.1935 7.50064 12C7.50064 10.8065 7.97475 9.66193 8.81866 8.81802C9.66257 7.97411 10.8072 7.5 12.0006 7.5C13.1941 7.5 14.3387 7.97411 15.1826 8.81802C16.0265 9.66193 16.5006 10.8065 16.5006 12C16.5006 13.1935 16.0265 14.3381 15.1826 15.182C14.3387 16.0259 13.1941 16.5 12.0006 16.5ZM12.0006 14.5C12.6637 14.5 13.2996 14.2366 13.7684 13.7678C14.2372 13.2989 14.5006 12.663 14.5006 12C14.5006 11.337 14.2372 10.7011 13.7684 10.2322C13.2996 9.76339 12.6637 9.5 12.0006 9.5C11.3376 9.5 10.7017 9.76339 10.2329 10.2322C9.76403 10.7011 9.50064 11.337 9.50064 12C9.50064 12.663 9.76403 13.2989 10.2329 13.7678C10.7017 14.2366 11.3376 14.5 12.0006 14.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeIcon",
};
</script>
